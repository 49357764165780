// COLORS
$primary-color: #1a7bb3;
$secondary-color: #4CC2F3;

$white-color: #fff;

$dark-grey: #333333;
$light-grey: #d2d2d2;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 3rem;
