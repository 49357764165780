#contact {
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  padding: 15rem 0 10rem 0;
  margin-top: -10rem;
  margin-bottom: -1px;
  color: $white-color;
  @include respond(tab-land) {
    padding: 10rem 0;
    clip-path: none;
    margin-top: 0;
    -webkit-clip-path: none;
  }

  .container {
    width: 90%;
    max-width: 825px;
  }

  .contact-wrapper {
    margin-top: 3.2rem;
    padding: 0 2rem;
    backface-visibility: hidden;

    &__text {
      margin-bottom: 2.5rem;
    }

    &__text,
    a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }

  .contact_form {
    .input_container {
      width: 100%;
      margin-bottom: 1.5rem;

      input,
      textarea {
        width: 100%;
        padding: 1rem;
        font-size: 1.5rem;
        border: transparent;
        outline-color: $primary-color;
      }
    }
    .cta-btn {
      background-color: transparent;
      width: 100%;
      padding: 1.75rem 2.5rem;
      font-size: 1.75rem;
    }
  }
}
